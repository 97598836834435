import http from '@amit/composables/src/useHttp';
import { useApi } from '@amit/composables';
export const fetchProject = project => useApi(http.get(`/projects/${project}`));

export const createEnrollments = (
  project: string,
  group: string,
  course: string,
  introduction = '',
) =>
  useApi(
    http.post(`/projects/${project}/enrollments`, {
      group,
      course,
      introduction,
    }),
  );

export const fetchEnrollments = (project: string, group: string) =>
  useApi(http.get(`/projects/${project}/enrollments`, { params: { group } }));

export const reviewSubmission = (project: string, enrollment, data) => {
  return useApi(
    http.post(`/projects/${project}/enrollments/${enrollment}/review`, data),
  );
};

export const unlockStage = (project: string, stage: string, group: string) => {
  return useApi(
    http.post(`/projects/${project}/stages/${stage}/unlock`, { group }),
  );
};

export const setProjectScore = ({
  project,
  enrollment,
  course,
  score,
  stage,
}: {
  project: string;
  enrollment: string;
  course: string;
  score: number;
  stage?: string;
}) => {
  return useApi(
    http.post(`/projects/${project}/enrollments/${enrollment}/score`, {
      course,
      score,
      stage,
    }),
  );
};
